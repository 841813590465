<template>
  <div class="admin">
    <iframe :src="url" frameborder="0"  width="100%" height="980px"
    scrolling="auto"></iframe>
  </div>
</template>

<script>

  import { local,aa,objKeySort } from '../../../util/util'
import { mapState } from "vuex";
import md5 from "js-md5";
export default {
  name: "emphasis",
  components: {},
  data() {
    return {
      url:'http://testmpapi.foodtrace.org.cn:8095/hljll/fr_company_hljimportant.do',
    };
  },
  watch: {

  },
  computed: {

  },
  mounted() {
    let user = local.get("user");
    this.url = this.url+"?user_type="+user.user_type;
  },
  created() {

  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
  .iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow-y: hidden;
  }
.slide-fade {
  position: absolute;
  left: 0;
  right: 0;
}
.slide-fade-enter-active {
  transition: all 1.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(2, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  left: 0;
  right: 0;
  transform: translateX(50px);
  opacity: 0;
}
.admin {
  a {
    color: unset;
  }
  .el-header {
    border-bottom: 1px solid #eee;
    line-height: 60px;
    text-align: right;
    img {
      width: 30px;
      vertical-align: middle;
    }
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-main {
    padding: 0px;
  }
  .el-menu {
    border-right: none;
  }
  .logo {
    width: 70px;
    height: 70px;
    margin-top: 40px;
  }
  .el-menu-item [class^="el-icon-"] {
    vertical-align: text-bottom;
  }
  .el-menu-item {
    margin-left: -10px;
  }
  /deep/ .el-menu-item-group__title {
    padding: 0px !important;
  }

  .el-menu-item,
  .el-submenu__title {
    i {
      font-size: 21px;
      color: #384147;
    }
  }

  /deep/ .el-submenu__title {
    padding-left: 22px !important;
  }
  .iconpeixun,
  .iconzuzhijigou {
    font-size: 18px !important;
  }
}
</style>
